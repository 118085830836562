import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select'
import { Button, CircularProgress } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import styled from 'styled-components';

const ErrorMessage = styled.span`
        font-weight: 500;
        margin-left: 10px;
        margin-top: 5px;
        display: block;
        color: rgb(255, 85, 85);
        font-size: 8pt;
    `

const PowerpackTwinContainer = ({ image, title, text }) => {
    const apiServer = process.env.GATSBY_API_ENDPOINT;
    // const apiServer = 'http://localhost:5000'
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [confirmed, setConfirmed] = useState(false)
    const { appState, sendEvent } = useContext(AppContext);
    const [prepurchaseState, setprepurchaseState] = useState({
        name: "",
        email: "",
        pack: "",
        comment: ""
    })

    const updateField = (name, value) => {
        setError("")
        setprepurchaseState({ ...prepurchaseState, [name]: value })
    }

    const handleSelectChange = e => {
        setError("")
        sendEvent("Powerpacks", "Click", `Change Selected Pack to: ${e.label}`)
        setprepurchaseState({ ...prepurchaseState, 'pack': e.label })
    }

    

    const onConfirm = () => {
        if(prepurchaseState.name && prepurchaseState.email && prepurchaseState.pack) {
            sendEvent("Powerpacks", "Click", "Click Reserve Powerpack")
            setLoading(true)
            const payload = {
                ...prepurchaseState,
                lang: appState.language
            }
            return fetch(`${apiServer}/powerpacks`, {
                method: "post",
                body: JSON.stringify(payload),
                headers: new Headers({
                    "Content-Type": "application/json",
                })
            })
            .then(response => {
                if (!response.ok) {
                    setLoading(false)
                    throw Error(response.statusText);
                } else {
                    setConfirmed(true)
                    setLoading(false)
                }
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
    
            })
        } else {
            sendEvent("Powerpacks", "Click", "Not Valid Click Reserve Powerpack")
            setError(appState.i18n.powerpacks.prepurchase_error)
        }
    }

    const powerPackOptions = [
        { value: 'PP20', label: 'PowerPack 20' },
        { value: 'PP40', label: 'PowerPack 40' },
        { value: 'PP60', label: 'PowerPack 60' },
    ]


    const powerBoxesOptions = [
        { value: 'PB6', label: 'PowerBox 6' },
        { value: 'PB10', label: 'PowerBox 10' },
        { value: 'PB14', label: 'PowerBox 14' }
    ]

    const expansion = [
        { value: 'Exp', label: appState.i18n.powerpacks.expansion },
    ]

    const groupedOptions = [
        {
            label: 'PowerPacks',
            options: powerPackOptions,
        },
        {
            label: appState.i18n.powerpacks.expansion_title,
            options: expansion,
        },
    ];

    const colorOptions = {
        control: styles => ({
            ...styles,
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
            backgroundColor: 'white',
            maxWidth: '280px',
            height: '40px',
            borderStyle: 'none',
            boxShadow: '6px 6px 11px 0 #e3e3e3, -6px -6px 11px 0 #ffffff',
        }),
        indicatorSeparator: styles => ({
            ...styles,
            backgroundColor: 'transparent',
        }),
        menu: styles => ({
            ...styles,
            maxWidth: '280px',
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? null : isSelected ? 'var(--primary-color)' : isFocused ? '#a5d65db3' : null,
                color: isDisabled ? 'black' : isSelected ? 'white' : isFocused ? 'black' : null,
            }
        }
    }

    return <div className="TwinContainer">
        <div className="TwinContainer-info-cont container-safety-border">
            <h1 className="TwinContainer-title title">
                {title}
            </h1>
            <h4 className="TwinContainer-text subtitle">{text}</h4>
            <div>
                <form className="vbox">
                    <div>
                        <label className="register-clienttype-label ">
                            {appState.i18n.powerpacks.name}
                        </label>
                        <input
                            onFocus={() => sendEvent("Powerpacks", "Focus", "Focus Name")}
                            required
                            onChange={(e) => {
                                updateField("name", e.target.value)
                            }}
                            value={prepurchaseState.name}
                            className="input registration-input-medium"
                            type="name"
                            maxLength="200"
                        />
                    </div>
                    <div>
                        <label className="register-clienttype-label ">
                            {appState.i18n.powerpacks.email}
                        </label>
                        <input
                            onFocus={() => sendEvent("Powerpacks", "Focus", "Focus Email")}
                            required
                            onChange={(e) => {
                                updateField("email", e.target.value)
                            }}
                            value={prepurchaseState.email}
                            className="input registration-input-medium"
                            type="email"
                            maxLength="200"
                        />
                    </div>
                    <div>
                        <label className="register-clienttype-label ">
                            {appState.i18n.powerpacks.option}
                        </label>
                        <Select placeholder={appState.i18n.powerpacks.select} onChange={e => handleSelectChange(e)} options={groupedOptions} styles={colorOptions} />
                    </div>
                    <div>
                        <label className="register-clienttype-label ">
                            {appState.i18n.powerpacks.form_comment} ({appState.i18n.all.optional})
                        </label>
                        <textarea
                            onFocus={() => sendEvent("Powerpacks", "Focus", "Focus Aditional Details Input")}
                            required
                            onChange={(e) => {
                                updateField("comment", e.target.value)
                            }}
                            value={prepurchaseState.comment}
                            className="input text-area"
                            maxLength="5000"
                            type="text"
                        />
                    </div>
                    <div>
                        {loading ? <div style={{margin: '40px 50px 0 50px'}}>
                                <CircularProgress />
                            </div> : 
                            <>
                                <Button
                                    readOnly
                                    style={{ marginTop: "40px", textAlign: "center" }}
                                    className="input-button button-default shadow"
                                    disabled={(!prepurchaseState.name && !prepurchaseState.email) || confirmed}
                                    onClick={onConfirm}
                                >
                                    {confirmed ? appState.i18n.powerpacks.reserved.toUpperCase() : appState.i18n.powerpacks.contactme.toUpperCase()}
                                </Button>
                                <ErrorMessage>{error}</ErrorMessage>
                            </>
                        }
                    </div>
                </form>
            </div>
        </div>
        <div className="TwinContainer-img-cont" style={{ backgroundImage: `url(${image})` }}>
            {/* <img className="TwinContainer-img" src={image} alt="energy image" /> */}
        </div>
    </div>
}

export default PowerpackTwinContainer;